import request from '@/util/request'
let train = '/api/train/'
// 官网培训列表 {current:当前页, size:长度, item:项目, title:标题, time:时间筛选 ,total_price:价格筛选}
export function getTrainList(params) {
  return request({
    url: `${train}trainInfo/frontList`,
    method: 'get',
    params
  })
}

// 官网培训详情 通过主键查询单条数据
export function getTrainInfo(params, id) {
  return request({
    url: `${train}trainInfo/${id}`,
    method: 'get',
    params
  })
}

// 官网培训详情 - 报名(旧的) {培训id：id}
export function trainApply(data) {
  return request({
    url: `${train}trainInfo/apply`,
    method: 'post',
    data
  })
}

// 官网培训详情 - 当前用户锁报名培训列表已报名 {培训id：id}
export function trainApplyList(params) {
  return request({
    url: `${train}trainInfo/applyList`,
    method: 'get',
    params
  })
}

// 官网培训详情 -- 培训报名:添加人员： 根据手机号码判断是否存在该用户,存在则返回用户信息
export function getUserByPhone(params, phone) {
  return request({
    url: `${train}Permission/Users/isExistPhone/${phone}`,
    method: 'get',
    params
  })
}

// 官网培训详情 - 添加报名数据 : 确认下一步
export function addApplyData(data) {
  return request({
    url: `${train}trainGroupApply/addApplyData`,
    method: 'post',
    data
  })
}

// 官网培训详情 - 获得当前报名次的报名信息
export function getApplyInfo(params) {
  return request({
    url: `${train}trainGroupApply/getApplyInfo`,
    method: 'GET',
    params
  })
}

// 官网培训详情 - 通过选定的预报名id生成订单，返回订单号
export function getOrderNumber(data) {
  return request({
    url: `${train}trainGroupApply/getOrderNumber`,
    method: 'post',
    data
  })
}

// 我的参与 - 培训 _获得当前用户报名的培训列表
export function getTrainListed(params) {
  return request({
    url: `${train}trainGroupApply/getTrainList`,
    method: 'GET',
    params
  })
}

// 我的参与 - 培训 _用户通查询自己的待确认列表
export function getPreListed(params) {
  return request({
    url: `${train}trainGroupApply/getPreList`,
    method: 'GET',
    params
  })
}

// 官网/我的参与 - 培训详情 _获得当前用户查看详情页时的状态{trainId:培训id}
//  {返回200时为没有任何状态,按钮为我要报名返回201时为存在帮别人报名的数据,返回 applytime 返回202时为被别人报名,需要确认,返回 applyData 返回203时为被别人报名已经确认,返回applytime}
export function getIsLeader(params, trainId) {
  return request({
    url: `${train}trainGroupApply/isLeader?trainId=${trainId}`,
    method: 'GET',
    params
  })
}

// 官网/我的参与 - 培训详情：确认参与 （用户确认） ：用户通过预报名id确认信息
export function useronfirm(data) {
  return request({
    url: `${train}trainGroupApply/confirm`,
    method: 'POST',
    data
  })
}

// 官网/我的参与 - 查询报名的订单信息
export function getApplyOrderInfo(params) {
  return request({
    url: `${train}trainGroupApply/getApplyOrderInfo`,
    method: 'GET',
    params
  })
}

// 官网报名-- 添加人员（获取之前添加过的人员信息） - 查询当前用户所填写过的报名数据
export function getApplyData(params) {
  return request({
    url: `${train}trainGroupApply/getApplyData`,
    method: 'GET',
    params
  })
}


// 官网报名-- 约束添加人员 - 根据用户id和培训id确认是否可以报名
export function canApply(params) {
  return request({
    url: `${train}trainInfo/canApply`,
    method: 'GET',
    params
  })
}

// 项目列表
export function getItemList() {
  return request({
    url: `/api/competitionUniversal/competitionItem/getItemList`,
    method: 'GET'
  })
}

// 新的接口
//培训活动列表(分页)
export function trainTrainPage(params) {
  return request({
    // url: '/api/train/train/page',
    url: `${train}train/front/page`,
    method: 'get',
    params
  })
}
//培训活动详情
export function trainTrainInfo(id) {
  return request({
    // url: '/api/train/train/info/' + id,
    url: `${train}train/front/info/${id}`,
    method: 'get',
  })
}
//网站-培训人员列表
export function trainTrainPersonnelList(params) {
  return request({
    url: `${train}train/personnel/appList`,
    // url: `/api/train/train/personnel/list`,
    method: 'get',
    params
  })
}
//网站-添加/培训人员
export function trainTrainPersonnelEdit(data) {
  return request({
    url: `${train}train/personnel/edit`,
    method: 'post',
    data
  })
}
//网站-删除培训人员
export function trainTrainPersonnelDel(id) {
  return request({
    url: `${train}train/personnel/del/${id}`,
    method: 'post',
  })
}
//网站-确认报名
export function trainTrainPersonnelConfirmSign(data) {
  return request({
    url: `${train}train/sign/personnel/confirm/sign`,
    method: 'post',
    data
  })
}
//导入培训活动参赛人员名单
export function importTrainPersonnel(data, type) {
  return request({
    url: `${train}train/excel/import?type=${type}`,
    method: 'post',
    data
  })
}
//培训报名人员校验
export function trainVerify(data) {
  return request({
    url: `${train}checkConfig/verify`,
    method: 'post',
    data
  })
}

//用户参与列表
export function trainSignPersonnelUserApplyList(data) {
  return request({
    url: `${train}train/sign/personnel/user/apply/list?name=${data.name}&size=${data.size}&current=${data.current}`,
    method: 'get',
    data
  })
}
//培训活动创建订单
export function trainPersonnelApplyOrder(data) {
  return request({
    url: `${train}train/sign/personnel/applyOrder`,
    method: 'POST',
    data
  })
}
//获取报名信息
export function trainSignPersonnelGetApplyPersonList(data) {
  return request({
    url: `${train}/train/sign/personnel/getApplyPersonList?name=${data.name}&size=${data.size}&current=${data.current}&trainId=${data.trainId}&orderNo=${data.orderNo}`,
    method: 'get',
    data
  })
}
