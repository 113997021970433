<template>
  <div class="wrapper-content">
    <!-- 培训查询 -->

    <el-form :inline="true" :model="queryParams" ref="queryParams" label-width="50px" class="search-bar">
      <el-form-item label="培训:" prop="title">
        <el-input v-model="queryParams.title" placeholder="请输入培训名称" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" plain @click="submitForm('queryParams')">搜索</el-button>
        <el-button @click="resetForm('queryParams')">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- 培训筛选 -->
    <div class="screening-bar">
      <ul>
        <li>
          <span class="screening-title"><i class="el-icon-location-outline" /> 地址:</span>
          <div class="screening-list">
            <span class="screening-type" @click="provinceClick(1, '','')" :class="{ active: queryParams.province == '' }">不限</span>
            <el-dropdown v-for="item in regionData" :key="item.value" @command="handleCommand" size="medium">
              <span class="el-dropdown-link screening-span" :class="{ active: queryParams.province == item.label }" @click="provinceClick(1, item.value,item.label)">{{ item.label }}</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="items in item.children" :key="items.value" :command="composeValue(item.value, items.value,item.label,items.label)">{{ items.label }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <a href="javascript:;" :key="0" @click="moreItem" class="screening-more">更多<i class="el-icon-arrow-down"/></a>
        </li>
        <li>
          <span class="screening-title"><i class="el-icon-menu" /> 项目: </span>
          <div class="screening-list">
            <span class="screening-type" @click="provinceClick(2, '')" :class="{ active: queryParams.itemCode == '' }">不限</span>
            <span class="screening-span" v-for="(item, i) in projectData" :key="i" @click="provinceClick(2, item.enCode, item.fullName)" :class="{ active: item.enCode == queryParams.itemCode }">{{ item.fullName }}</span>
          </div>
          <a href="javascript:;" :key="0" @click="moreItem" class="screening-more">更多<i class="el-icon-arrow-down"/></a>
        </li>
        <li>
          <span class="screening-title"><i class="el-icon-time" /> 时间: </span>
          <div class="screening-list">
            <el-date-picker v-model="dateValue" type="daterange" range-separator="至" start-placeholder="培训开始日期" end-placeholder="培训结束日期" style="margin-left: 6px" value-format="yyyy-MM-dd" @change="pickChange"></el-date-picker>
          </div>
        </li>
        <li>
          <span class="screening-title"><i class="el-icon-wallet" /> 费用:</span>
          <div class="screening-list">
            <span class="screening-type" @click="provinceClick(3, '','')" :class="{ active: queryParams.collectFee === '' }">不限</span>
            <span class="screening-span" v-for="(item, i) in costData" :key="i" @click="provinceClick(3, item.value)" :class="{ active: item.value === queryParams.collectFee }">{{ item.name }}</span>
          </div>
        </li>
        <li>
          <span class="screening-title"><i class="el-icon-edit-outline" /> 模式:</span>
          <div class="screening-list">
            <span class="screening-type" @click="provinceClick(4, '')" :class="{ active: modelOnlineModel == '' }">不限</span>
            <span class="screening-span" v-for="(item, i) in typeList" :key="i" @click="provinceClick(4, item.value)" :class="{ active: item.value == modelOnlineModel }">{{ item.label }}</span>
          </div>
        </li>
      </ul>
    </div>
    <!-- 培训活动 -->
    <div class="in_competition">
      <div class="in_competition-item" v-for="item in trainList" :key="item.id" @click="goDetail(item.id)">
        <el-image class="in_competition-img" :src="loadUrl(item.image)" fit="cover"></el-image>
        <div class="in_competition-text">
          <h3>
            <el-tag v-if="item.status == 5" type="success" effect="dark" size="mini">筹备中</el-tag>
            <el-tag v-if="item.status == 7" type="danger" effect="dark" size="mini">报名中</el-tag>
            <el-tag v-if="item.status == 8" type="info" effect="dark" size="mini">报名已结束</el-tag>
            <el-tag v-if="item.status == 9" effect="dark" size="mini">{{item.category==1?'活动':'培训'}}中</el-tag>
            <el-tag v-if="item.status == 10" type="info" effect="dark" size="mini">{{item.category==1?'活动':'培训'}}已结束</el-tag>
            {{ item.name }}
          </h3>
          <span class="date-text">{{item.category==1?'活动':'培训'}}时间：{{ formatDateH(item.hostStartTime) }}至{{ formatDateH(item.hostEndTime) }}</span>
          <span class="date-text">{{item.category==1?'活动':'培训'}}地址：{{item.province}}{{item.city}}{{item.area}}{{item.address}}</span>
        </div>
      </div>
    </div>
    <!-- page -->
    <el-pagination v-if="total > 0" class="page-bar" background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryParams.currentPage" :page-sizes="[8, 16, 24, 32]" :page-size="queryParams.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
  </div>
</template>

<script>
import { regionData } from "element-china-area-data";
import { getDictionaryDataList } from "@/api/dict";
import { trainTrainPage } from "../../api/train";
export default {
  name: "train-index",
  components: {},
  data() {
    return {
      trainList: [],
      total: 0,
      queryParams: {
        currentPage: 1,
        pageSize: 8,
        keyword: "",
        province: "",
        city: "",
        area: "",
        itemCode: "",
        hostStartTime: "",
        hostEndTime: "",

        collectFee: "",//是否收费（0否、1是）
        model: "",//活动模式（1线下、2线上）
        onlineModel: "",//线上模式（1视频、2直播）
        status: "5,7,8,9,10",
      },
      modelOnlineModel:'',
      provinceLabel: "",
      dateValue: "",
      isMore: false,
      regionData: regionData,
      projectData: [],
      costData: [
        { name: "免费", value: 0 },
        { name: "收费", value: 1 },
      ],
      typeList: [
        { label: "直播培训", value: 1 },
        { label: "视频培训", value: 2 },
        { label: "线下培训", value: 3 },
      ],
      itemCode: "261a37e11630402c9773616a62601450",
    };
  },
  watch: {
    dateValue(val) {
      if (!val) {
        this.queryParams.hostStartTime = "";
        this.queryParams.hostEndTime = "";
        this.init();
      }
    },
  },
  created() {
    this.$store.dispatch("saveHeadActive", "/train");
    // 数据字典
    getDictionaryDataList(this.itemCode).then((res) => {
      this.projectData = res.data.list;
    });
    this.init();
  },
  methods: {
    init() {
      const self = this;
      self.$store.commit("showLoading");
      trainTrainPage(this.queryParams).then((res) => {
        if (res.code == 200) {
          this.trainList = res.data.list;
          this.total = res.data.pagination.total;
        } else {
          self.$message({ message: res.msg, duration: 1500 });
        }
        self.$store.commit("hideLoading");
      });
    },
    // 时间选择
    pickChange(val) {
      this.queryParams.hostStartTime = val[0];
      this.queryParams.hostEndTime = val[1];
      this.$nextTick(() => {
        this.init();
      });
    },
    // 省份点击事件
    provinceClick(num, val,name) {
      switch (num) {
        case 1:
          this.queryParams.province = name;
          this.queryParams.city = "";
          break;
        case 2:
          this.queryParams.itemCode = val;
          this.queryParams.itemName = name;
          break;
        case 3:
          this.queryParams.collectFee = val;
          break;
        case 4:
          if(val == 1){
            this.queryParams.model = 2
            this.queryParams.onlineModel = 2
          }else if(val == 2){
            this.queryParams.model = 2
            this.queryParams.onlineModel = 1
          }else if(val == 3){
            this.queryParams.model = 1
            this.queryParams.onlineModel = ''
          }
          this.modelOnlineModel = val
          break;
      }
      this.$nextTick(() => {
        this.init();
      });
    },
    // 市区点击事件
    handleCommand(val) {
      this.queryParams.city = val.cLable;
      this.queryParams.province = val.pLabel;
      this.$nextTick(() => {
        this.init();
      });
    },
    composeValue(province, city,pLabel,cLable) {
      return {
        province: province,
        city: city,
        pLabel:pLabel,
        cLable:cLable
      };
    },
    submitForm() {
      this.queryParams.current = 1;
      this.init();
    },
    resetForm() {
      this.queryParams = {
        currentPage: 1,
        pageSize: 8,
        keyword: "",
        province: "",
        city: "",
        area: "",
        itemCode: "",
        hostStartTime: "",
        hostEndTime: "",

        collectFee: "",//是否收费（0否、1是）
        model: "",//活动模式（1线下、2线上）
        onlineModel: "",//线上模式（1视频、2直播）
      }
      this.modelOnlineModel = ''
      this.init();
    },
    moreItem(el) {
      let _el = el.currentTarget;
      let prev = el.currentTarget.previousElementSibling;
      _el.innerHTML = "";

      this.isMore = !this.isMore;
      if (!this.isMore) {
        _el.innerHTML = '更多<i class="el-icon-arrow-down" />';
        prev.style.height = "40px";
      } else {
        _el.innerHTML = '收起<i class="el-icon-arrow-up" />';
        prev.style.height = "auto";
      }
    },
    handleSizeChange(val) {
      this.$set(this.queryParams, "size", val);
      this.queryParams.currentPage = 1;
      this.init();
    },
    handleCurrentChange(val) {
      this.queryParams.currentPage = val;
      this.init();
    },
    goDetail(id) {
      this.$router.push(`train-detail/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.screening-bar {
  padding: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: start;
    // padding: 10px 0;
    font-size: 14px;
    line-height: 20px;
    color: #555;
  }
  .screening-title {
    margin: 10px 5px;
    font-size: 14px;
    color: #555;
    flex-shrink: 0;
  }
}

.screening-type,
.screening-list .screening-span {
  display: inline-block;
  cursor: pointer;
  margin: 10px 5px;
  padding: 0 10px;
  color: #555;
  border-radius: 3px;
}
.screening-type,
.screening-more {
  flex-shrink: 0;
  height: 20px;
}
.screening-list {
  flex-grow: 1;
  height: 40px;
  overflow: hidden;
}
.screening-more {
  margin-top: 10px;
  color: #999;
}

.screening-bar li .screening-type:hover,
.screening-bar li .screening-type.active,
.screening-bar li .screening-span:hover,
.screening-bar li .screening-span.active {
  background-color: #1e50ae;
  color: #fff;
}
</style>
